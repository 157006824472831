import React, { useState, useRef, useEffect } from "react";
import {
	PlusOutlined,
} from "@ant-design/icons";
import {
	Space,
	Skeleton,
	Drawer,
	Button,
	Select,
	message,
	Input,
	Table,
	Tag,
	Modal,
	Switch,
	Tooltip
} from "antd";
import { 
	get_recurrencies, 
	create_recurrency, 
	toggle_recurrency 
} from "../../services/recurrencies";
import { edit_invoice } from "../../services/invoices";
import PropTypes from "prop-types";

const no_created_recurrency = {
	default: false,
	key_words: [],
	accounting_classification: "",
	analytic_classification: "",
};

const Classification_Recurrencies = ({
	token,
	company,
	position
}) => {

	const [loadingRecurrencies, setLoadingRecurrencies] = useState(false);
	const [loadingReClassify, setLoadingReClassify] = useState(false);
	const [loadingToggle, setLoadingToggle] = useState(false);
	const [openCreateRecurrency, setOpenCreateRecurrency] = useState(false);
	const [openRecurrencies, setOpenRecurrencies] = useState(false);

	const [recurrencies, setRecurrencies] = useState([]);
	const [recurrencyToCreate, setRecurrencyToCreate] = useState(no_created_recurrency);

	const [tags, setTags] = useState([]);
	const [inputVisible, setInputVisible] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [editInputIndex, setEditInputIndex] = useState(-1);
	const [editInputValue, setEditInputValue] = useState("");
	const inputRef = useRef(null);
	const editInputRef = useRef(null);

	const accounting_classification = position == "in"? company.accounting_resources.sale_accounts : company.accounting_resources.expense_accounts;

	const handleCreateRecurrency = async () => {
		setOpenCreateRecurrency(false);
		setLoadingRecurrencies(true);
		
		var rec = recurrencyToCreate;
		rec.key_words = tags;
		rec.accounting_classification = `${rec.accounting_classification}`;
		rec.analytic_classification = `${rec.analytic_classification}`;
		
		if (company["id"] != "") {
			try {
				const res = await create_recurrency(token, "invoice_classification", company.tid, company.id, rec, position);
				if (res) {
					handleRecurrenciesModal();
				}
			} catch (error) {
				setLoadingRecurrencies(false);
			}
		}
	};

	const toggleRecurrency = async (item) => {
		if (company["id"] != "") {
			try {
				setLoadingToggle(true);
				const res = await toggle_recurrency(token, item.id);
				if (res) {
					message.success("Ha cambiado el estado de activación de la recurrencia con éxito");
					handleRecurrenciesModal();
				}
			} catch (error) {
				setLoadingToggle(false);
				message.error("Ocurrió un error cambiando el estado de la activación de la recurrencia");
			}
		}
	};

    
	const handleRecurrenciesModal = async () => {
        
		if (company["id"] != "") {
			setOpenRecurrencies(true);
			setLoadingRecurrencies(true);
			try {
				const res = await get_recurrencies(token, "invoice_classification", company["id"], position);
				if (res) {
					if (res.length == 0) {
						message.info("No hay recurrencias");
					}
					setRecurrencies(res);
					setLoadingRecurrencies(false);
					setLoadingToggle(false);
				}
			} catch (error) {
				message.error(error.message);
				setLoadingRecurrencies(false);
			}
		}
		else {
			message.error("Debe seleccionar una compañía");
		}


	};

	const onCloseRecurrencies = () => {
		setOpenRecurrencies(false);
		setRecurrencyToCreate(no_created_recurrency);
		setRecurrencies([]);
	};


	useEffect(() => {
		if (inputVisible) {
			inputRef.current?.focus();
		}
	}, [inputVisible]);

	useEffect(() => {
		editInputRef.current?.focus();
	}, [editInputValue]);

	const handleClose = (removedTag) => {
		const newTags = tags.filter((tag) => tag !== removedTag);
		setTags(newTags);
	};

	const showInput = () => {
		setInputVisible(true);
	};

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleInputConfirm = () => {
		if (inputValue && !tags.includes(inputValue)) {
			setTags([...tags, inputValue]);
		}
		setInputVisible(false);
		setInputValue("");
	};

	const handleEditInputChange = (e) => {
		setEditInputValue(e.target.value);
	};

	const handleEditInputConfirm = () => {
		const newTags = [...tags];
		newTags[editInputIndex] = editInputValue;
		setTags(newTags);
		setEditInputIndex(-1);
		setEditInputValue("");
	};

	const tagInputStyle = {
		width: 150,
		height: 22,
		marginInlineEnd: 8,
		verticalAlign: "top",
	};

	const tagPlusStyle = {
		height: 22,
		background: "white",
		borderStyle: "dashed",
	};

	function findAccountNameById(accountingPlan, accountId) {
		// Buscar el elemento en accountingPlan que tenga un id que coincida con accountId
		const matchingElement = accountingPlan.find(planItem => planItem.id.toString() === accountId.toString());
      
		// Si se encuentra un elemento coincidente, retorna su propiedad 'name'
		if (matchingElement) {
			if (!matchingElement.display_name) {
				return matchingElement.name;
			}
			return matchingElement.display_name;
		} else {
			// Si no se encuentra ninguna coincidencia, retorna null o un mensaje indicativo
			return null; // o "No matching account found"
		}
	}

	function elegirColorAleatorio() {
		// Definir un arreglo de colores posibles
		const colores = ["magenta", "volcano", "orange", "gold", "green", "cyan", "blue", "geekblue", "purple"];
      
		// Elegir un índice aleatorio basado en la longitud del arreglo de colores
		const indiceAleatorio = Math.floor(Math.random() * colores.length);
      
		return colores[indiceAleatorio];
	}

	const handleReClassifyInvoices = async () => {
		
		if (company["id"] != "") {
			setLoadingReClassify(true);
			try {
				const res = await edit_invoice(token, "reclassify", {company_tid: company["tid"]}, position);
				if (res) {	
					message.success("Facturas Reclasificadas");
					message.info("Para ver los cambios debes buscar las facturas de nuevo", 5);
					setLoadingReClassify(false);
				}
			} catch (error) {
				message.error(error.message);
				setLoadingReClassify(false);
			}
		}
		else {
			message.error("Debe seleccionar una compañía");
		}
	};
    
	return (<>
		<Button
			style={{
				marginLeft: "10px",
			}}
			loading={loadingRecurrencies}
			onClick={() => {
				handleRecurrenciesModal();
			}}
		>
				Recurrencias
		</Button>
		{
			company.id != "" &&
				<Drawer 
					title="Recurrencias" 
					onClose={onCloseRecurrencies} 
					open={openRecurrencies}
					width="80%"
					extra={
						<>
							<Button
								style={{
									marginLeft: "10px",
								}}
								loading={loadingReClassify}
								onClick={handleReClassifyInvoices}>
								Re-Clasificar Facturas
							</Button>
							<Button
								type="primary"
								style={{
									marginLeft: "10px",
								}}
								loading={loadingRecurrencies}
								onClick={() => {setOpenCreateRecurrency(true);}}>
								Crear Recurrencia
							</Button>
						</>
					}
				>
					{
						recurrencies.length == 0 &&
                            <   Skeleton active={loadingRecurrencies} />
					}
					{
						recurrencies.length !== 0 &&
							<Table
								pagination={{
									position: "top",
									align: "end",
									disabled: false,
									showSizeChanger: false,
									pageSize: 8,
								}}
								columns={[
									{
										title: "Palabras Clave",
										dataIndex: "key_words",
										key: "key_words",
										render: (tags) => (
											<>
												{tags.map((tag) => {
													const color = elegirColorAleatorio();
													const is_large = tag.length > 20;
													if (is_large)
														return (
															<Tooltip title={tag} key={tag}>
																<Tag color={color} key={tag}>
																	{is_large ? `${tag.slice(0, 20)}...` : tag}
																</Tag>
															</Tooltip>
														);
													else
														return (
															<Tag color={color} key={tag}>
																{tag}
															</Tag>
														);
												})}
											</>
										),
										defaultSortOrder: "descend",
										sorter: (a, b) => a.key_words.length - b.key_words.length,
									},
									{
										title: "Activo",
										dataIndex: "active",
										key: "active",
										render: (active, item) => (
											<Switch loading={loadingToggle} checked={active} onChange={() => toggleRecurrency(item)} />
										),
										sorter: (a, b) => a.active - b.active,
									},
									{
										title: "Por defecto",
										dataIndex: "default",
										key: "default",
										render: (text) => (
											text ? 
												<Tag color={"Green"} key={1}>
                                        Por Defecto
												</Tag> : 
												<Tag color={"gray"} key={1}>
                                        X
												</Tag>
										),
									},
									{
										title: "Cuenta Contable",
										dataIndex: "accounting_classification",
										key: "accounting_classification",
										render: (text) => (
											<p>{text}</p>
										),
									},
									{
										title: "Cuenta Analítica",
										dataIndex: "analytic_classification",
										key: "analytic_classification",
										render: (text) => (
											text ? <p>{text}</p> : <p>Sin Clasificación</p>
										),
									},
								]} 
								dataSource={recurrencies.map((item, index) => ({
									key: index,
									active: item.active,
									default: item.specs.default,
									key_words: item.specs.key_words,
									accounting_classification: findAccountNameById(company.accounting_resources.accounting_plan,item.specs.accounting_classification),
									analytic_classification: findAccountNameById(company.accounting_resources.others.analytic_accounts,item.specs.analytic_classification),
									id: item.id
								}))}
							/>
					}
					<Modal
						centered
						open={openCreateRecurrency}
						onOk={() => handleCreateRecurrency()}
						onCancel={() => setOpenCreateRecurrency(false)}
					>
						<h3>Clasificación por defecto:</h3>
						<Switch onChange={() => {setRecurrencyToCreate({...recurrencyToCreate, default: !recurrencyToCreate.default});}} />
						<h3>Palabras Clave:</h3>
						<Space size={[0, 8]} wrap>
							{tags.map((tag, index) => {
								if (editInputIndex === index) {
									return (
										<Input
											ref={editInputRef}
											key={tag}
											size="small"
											style={tagInputStyle}
											value={editInputValue}
											onChange={handleEditInputChange}
											onBlur={handleEditInputConfirm}
											onPressEnter={handleEditInputConfirm}
										/>
									);
								}
								const isLongTag = tag.length > 20;
								const tagElem = (
									<Tag
										key={tag}
										closable={true}
										style={{
											userSelect: "none",
										}}
										onClose={() => handleClose(tag)}
									>
										<span
											onDoubleClick={(e) => {
												if (index !== 0) {
													setEditInputIndex(index);
													setEditInputValue(tag);
													e.preventDefault();
												}
											}}
										>
											{isLongTag ? `${tag.slice(0, 20)}...` : tag}
										</span>
									</Tag>
								);
								return isLongTag ? (
									<Tooltip title={tag} key={tag}>
										{tagElem}
									</Tooltip>
								) : (
									tagElem
								);
							})}
							{inputVisible ? (
								<Input
									ref={inputRef}
									type="text"
									size="small"
									maxLength={20}
									showCount
									style={tagInputStyle}
									value={inputValue}
									onChange={handleInputChange}
									onBlur={handleInputConfirm}
									onPressEnter={handleInputConfirm}
								/>
							) : (
								<Tag 
									style={tagPlusStyle} 
									icon={<PlusOutlined />} 
									onClick={showInput}>
								New Tag
								</Tag>
							)}
						</Space>
						<p>Cuenta Contable</p>
						<Select
							showSearch
							placeholder="Clasificación"
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							value={
								recurrencyToCreate.accounting_classification
							}
							style={{
								minWidth: "100px",
								width: "100%",
							}}
							popupMatchSelectWidth={false}
							options={
								company.accounting_resources.accounting_plan && accounting_classification
									? company.accounting_resources.accounting_plan
										.filter((element) =>
											accounting_classification.includes(
												element.id
											)
										)
										.map((element) => ({
											label: element.display_name,
											value: element.id,
										}))
									: []
							}
							onChange={(element) =>
								setRecurrencyToCreate({...recurrencyToCreate, accounting_classification: element})
							}
						/>
						<p>Cuenta Analítica</p>
						<Select
							showSearch
							placeholder="Analíticas"
							filterOption={(input, option) =>
								(option?.label ?? "")
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							status=""
							value={
								recurrencyToCreate.analytic_classification
							}
							style={{
								minWidth: "100px",
								width: "100%",
							}}
							popupMatchSelectWidth={false}
							options={
								company.accounting_resources.others.analytic_accounts
									? company.accounting_resources.others.analytic_accounts
										.map((element) => ({
											label: element.name,
											value: element.id,
										}))
									: []
							}
							onChange={(element) =>
								setRecurrencyToCreate({...recurrencyToCreate, analytic_classification: element})
							}
						/>
					</Modal>
				</Drawer>
		}
	</>);
};

Classification_Recurrencies.propTypes = {
	token: PropTypes.string.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string,
		tid: PropTypes.string,
		accounting_resources: PropTypes.shape({
			accounting_plan: PropTypes.any,
			expense_accounts: PropTypes.any,
			sale_accounts: PropTypes.any,
			others: PropTypes.shape({
				analytic_accounts: PropTypes.any
			})
		})
	}),
	position: PropTypes.string,
};

export default Classification_Recurrencies;