/* eslint-disable react/prop-types */

import React from "react";
import { CSVLink } from "react-csv";

const ReporteExcel = ({ data }) => {
	const headers = Object.keys(data[0]);

	const csvData = [headers, ...data.map(item => Object.values(item))];

	return (
		<div
			style={{
				width:"100%",
				height:"100%",
				textAlign:"center",
				paddingTop:5,
			}}
		>
			<CSVLink
				
				data={csvData} 
				filename="reporte.csv">
					Descargar Reporte
			</CSVLink>
		</div>
	);
};

export default ReporteExcel;
