import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
// const apiTest = "http://127.0.0.1:5000";

export const get_invoices_report_through_mail = async (token, nit, type, state, startDate, endDate, email) => {
	try {
		const res = await axios.post(`${apiUrl}/invoices_csv_through_mail`, {
			token,
			nit,
			type,
			state,
			startDate,
			endDate,
			email
		});
		return res;
	} catch (err) {
		console.error(err);
		throw err;
	}
};
