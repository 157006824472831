import React from "react";
import { Result } from "antd";
import PropTypes from "prop-types";

const Start = ({company}) => {
	return (
		<>
			{
				company.looker_link != null &&
				<iframe 
					width="100%" 
					height="100%" 
					src={company.looker_link}
					frameBorder="0"
					allowFullScreen
				></iframe>
			}
			{
				company.looker_link == null &&
            <Result>
                Esta empresa no posee un reporte
            </Result>
			}
		</>
	);
};

Start.propTypes = {
	company: PropTypes.shape({
		looker_link: PropTypes.string
	}).isRequired
};

export default Start;