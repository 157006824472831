import React, { useState } from "react";
import "./App.css";
import Login from "./pages/login/login";
import Home from "./pages/home/home";

function App() {
	const [user, setUser] = useState({});
	const [token, setToken] = useState({});
	return (
		<div className="App">
			{Object.keys(user).length != 0 && (
				<Home
					user={user}
					token={token}
					setUser={setUser}
					setToken={setToken}
					style={{
						backgroundColor: "#433300",
					}}
				></Home>		
			)}
			{Object.keys(user).length == 0 && (
				<Login
					setUser={setUser}
					setToken={setToken}
				></Login>
			)}
		</div>
	);
}

export default App;
