import React, { useState }  from "react";
// import { CSVLink } from "react-csv";
import { 
	DownloadOutlined
} from "@ant-design/icons";
import { 
	Select, 
	DatePicker,
	Button,
	message,
	Typography
} from "antd";
import dayjs from "dayjs";
import { get_invoices_report_through_mail } from "../../services/reports";
import PropTypes from "prop-types";

const { RangePicker } = DatePicker;
const { Title } = Typography;

const rangePresets = [
	{
		label: "Last 7 Days",
		value: [dayjs().add(-7, "d"), dayjs()],
	},
	{
		label: "Last 14 Days",
		value: [dayjs().add(-14, "d"), dayjs()],
	},
	{
		label: "Last 30 Days",
		value: [dayjs().add(-30, "d"), dayjs()],
	},
	{
		label: "Last 90 Days",
		value: [dayjs().add(-90, "d"), dayjs()],
	},
];

const Reports = ({user, token, company}) => {
	const [selectedState, setselectedState] = useState(null);
	const [selectedType, setselectedType] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [loadingReporteFacturas, setloadingReporteFacturas] = useState(false);

	const onChangeState = (value) => {
		setselectedState(value);
	};

	const onChangeType = (value) => {
		setselectedType(value);
	};

	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			setStartDate(dateStrings[0]);
			setEndDate(dateStrings[1]);
			return;
		}
		setStartDate(null);
		setEndDate(null);
	};

	const onDownloadInvoices = async () => {
		if (
			company.id === "" ||  selectedState === null || selectedType === null || 
      startDate === null ||  endDate === null
		) {
			message.error("No has seleccionado todos los valores");
			return;
		}
    
		setloadingReporteFacturas(true);

		let date = new Date(endDate);

		// Agregar un día
		date.setDate(date.getDate() + 1);

		// Convertir de nuevo a cadena de texto
		let newEndDate = date.toISOString().split("T")[0];

		try {
			const res = await get_invoices_report_through_mail(
				token, 
				company.tid, 
				selectedType, 
				selectedState, 
				startDate, 
				newEndDate, 
				user.email
			);
			if (res) {
				if(res.data.state === false){
					message.warning(res.data.message);
				}
				if(res.data.state === true){
					message.success(res.data.message);
				}
				setloadingReporteFacturas(false);
			}
		} catch (error) {
			console.log(error);
			setloadingReporteFacturas(false);
			message.error(error.message);
		}
	};

	return (
		<>
			<Title>Reporte de Facturas</Title>
			<Select
				showSearch
				placeholder="Selecciona un tipo"
				optionFilterProp="children"
				onChange={onChangeType}
				filterOption={(input, option) =>
					(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
				}
				style={{
					marginLeft:"10px"
				}}
				options={[
					{
						value:"out",
						label:"Compra"
					},
					{
						value:"in",
						label:"Venta"
					},
				]}
			/>
			<Select
				showSearch
				placeholder="Selecciona un estado"
				optionFilterProp="children"
				onChange={onChangeState}
				filterOption={(input, option) =>
					(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
				}
				style={{
					marginLeft:"10px"
				}}
				options={[
					{
						value:"unverified",
						label:"Sin Verificar"
					},
					{
						value:"verified",
						label:"Verificada"
					},
					{
						value:"excluded",
						label:"Excluída"
					},
					{
						value:"history",
						label:"Historia"
					},
					{
						value:"canceled",
						label:"Anulada"
					},
					{
						value:"error",
						label:"Error"
					}
				]}
			/>
			<RangePicker presets={rangePresets} style={{
				marginLeft:"10px",
				marginTop:"10px"
			}} onChange={onRangeChange} />
			<div
				style={{
					marginTop:"10px"
				}}
			>
				<Button 
					type="primary" 
					icon={<DownloadOutlined />} 
					size='large'
					onClick={onDownloadInvoices}
					loading={loadingReporteFacturas}
				>
          Enviar Reporte
				</Button>
			</div>
    
		</>
	);
};

Reports.propTypes = {
	user: PropTypes.shape({
		email: PropTypes.string.isRequired
	}).isRequired,
	token: PropTypes.string.isRequired,
	company: PropTypes.shape({
		id: PropTypes.string.isRequired,
		tid: PropTypes.string.isRequired,
	}).isRequired
};

export default Reports;
