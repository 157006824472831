import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

export const get_recurrencies = async (token, type, company_id, position) => {
	var body = {
		token,
		type,
		company_id,
		position
	};

	try {
		const res = await axios.post(`${apiUrl}/get_recurrencies`, body);
		return res.data;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const create_recurrency = async (token, type, company_tid, company_id, specs, position) => {
	var body = {
		token,
		edit_value: "create",
		data: {
			type,
			receptor_id: position == "out" ? company_tid : "",
			sender_id: position == "in"? company_tid : "",
			specs,
			company_id,
			position
		}
	};

	console.log(body);

	try {
		const res = await axios.post(`${apiUrl}/edit_recurrency`, body);
		return res.data;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const toggle_recurrency = async (token, id) => {
	var body = {
		token,
		edit_value: "toggle_activation",
		data: {
			id
		}
	};

	try {
		const res = await axios.post(`${apiUrl}/edit_recurrency`, body);
		return res.data;
	} catch (err) {
		console.error(err);
		throw err;
	}
};